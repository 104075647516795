import styled from "@emotion/styled";
import { fonts } from "styles/fonts";
import { colors } from "styles/colors";
import { CheckboxWrap, CheckboxIcon } from "shared/Checkbox/styled";
import { NoImage, Spinner } from "styles/App.styled";

/* Style CardOverlay */
export const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const CardTime = styled.div`
  margin-right: 6px;
  display: inline-block;
  letter-spacing: 0.01em;
  color: ${colors.GREY_DARK};
  font-size: 14px;
  font-family: ${fonts.latoRegular};
  text-align: right;
  b {
    color: ${colors.GREY_DARK};
  }
`;

export const LefoverBanner = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  width: 96px;
  height: 24px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;

  img {
    width: 16px;
    height: 16px;
    border-radius: unset;
    position: relative !important;
    top: unset;
    left: unset;
    background: unset;
  }

  span {
    color: #111111;
    font-size: 14px;
    display: block;
    padding-top: 4px;
    line-height: normal;
    height: 24px;
  }
`;

/* Style Card */
export const CardImage = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @media (min-width: 360px) {
    margin-bottom: 14px;
  }
  margin-bottom: 14px;
  width: 100%;
  img {
    background: ${colors.GREY_LIGHT} url("/images/noimage.png") no-repeat 50% 50%;
    background-size: 127px 127px;
  }
  img,
  ${NoImage} {
    border-radius: 20px;
    display: block;
    position: absolute !important;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  ${NoImage} {
    transform: translate(-50%, -50%);
  }
  img.cloudfront {
    top: 0;
    left: 0;
  }
  a {
    > div {
      position: unset !important;
      height: 100%;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(34, 36, 36, 0) 54.17%, rgba(34, 36, 36, 0.5) 100%);
    }
  }
  ${CardTime} {
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.45);
  }
`;

export const CardImageType1 = styled(CardImage)`
  position: relative;
  @media (min-width: 360px) {
    margin-bottom: 9px;
  }
  ${CardTime} {
    margin-left: auto;
  }
`;

export const ViewsNumber = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 5px;
    margin-top: 2px;
  }
`;

export const CardBody = styled.div``;

export const CardBodyInfo = styled.div`
  text-align: center;

  @media screen and (min-width: 991px) {
    flex: 1;
    text-align: left;
  }
`;

export const CardTitle = styled.div`
  cursor: pointer;
  margin-bottom: 3px;
  font-family: ${fonts.latoBold};
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RecipeOwner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 16px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    color: #111;
    font-family: ${fonts.rubikRegular};
    font-size: 14px;
  }
`;

export const CardRecipeYield = styled.div`
  cursor: pointer;
  margin-bottom: 3px;
  font-family: ${fonts.rubikRegular};
  color: var(--grey-700, #686868);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CardInfo = styled.div`
  margin-top: 10px;

  @media (min-width: 769px) {
    margin-bottom: 18px;
  }
`;

/* Style CardWrap */
export const Column = styled.div``;

export const CardSelect = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  ${CheckboxWrap} {
    position: relative;
    z-index: 1;
  }
  label,
  ${CheckboxWrap}, ${CheckboxIcon} {
    margin: 0;
  }
`;

export const CardTags = styled.div`
  margin-bottom: 10px;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    justify-content: center;
  }

  span {
    text-decoration: underline;
    margin-right: 6px;
  }
`;

export const DraftActions = styled.div`
  min-width: 190px;
  margin-left: auto;

  button {
    display: block;
    width: 250px;
    margin: 0 auto 15px;

    @media screen and (min-width: 991px) {
      width: 100%;
      margin: 0 0 15px;
    }
  }
`;

export const Card = styled.div`
  padding: ${(props) => (props.type === "draft" ? `0` : `0 15px`)};
  line-height: 1.2;
  ${CardImageType1},
  ${CardImage} {
    padding-top: 100%;
    max-width: 240px;
    max-height: 240px;
    border-radius: 20px;
    ${(props) =>
      props.type === "draft"
        ? `     
      > .icon {
        position: absolute; 
        left: 15px;
        bottom: 15px;
        stroke: ${colors.WHITE};
      }         
      `
        : `
    `}
  }
  > .icon {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 24px;
    height: 24px;
    stroke: ${colors.WHITE};
  }

  a {
    text-decoration: none;
  }

  &.horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    margin-top: 30px;
    + .horizontal {
      padding-top: 30px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        display: block;
        height: 1px;
        background-color: ${colors.GREY_LIGHT};
      }
    }
    @media screen and (min-width: 640px) {
      flex-direction: row;
      align-items: flex-start;
    }

    ${CardImage} {
      min-width: 250px;
      width: 250px;
      padding-top: 250px;
      margin-bottom: 0;

      @media screen and (min-width: 640px) {
        margin-right: 30px;
      }
    }

    ${CardTitle} {
      font-size: 24px;
      font-family: ${fonts.abhayaBold};
      margin-bottom: 15px;
    }

    ${CardTime} {
      margin-bottom: 10px;
    }

    ${CardInfo} {
      text-decoration: none;
    }

    ${CardBody} {
      flex: 1;

      @media screen and (min-width: 991px) {
        display: flex;
      }
    }

    ${CardBodyInfo} {
      @media screen and (min-width: 991px) {
        margin-right: 15px;
      }
    }
  }
`;

export const PlayVideo = styled.div`
  color: ${colors.WHITE};
  height: 30px;
  width: 50px;
  background-color: rgba(32, 36, 41, 0.35);
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  border-radius: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    fill: ${colors.WHITE};
    stroke: ${colors.WHITE};
  }
`;

export const PaymentBackground = styled.div`
  color: ${colors.WHITE};
  line-height: 30px;
  font-family: ${fonts.latoBold};
  font-size: 14px;
  height: 30px;
  min-width: ${(props) => (props.private ? `36px` : `55px`)};
  background-color: rgba(32, 36, 41, 0.35);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 20px 0 20px;
  text-align: center;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    stroke: ${colors.WHITE};
  }
  &.creator {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 10px;
    .icon {
      margin-bottom: 2px;
      margin-right: 7px;
      position: static;
      transform: none;
    }
  }
`;

export const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  @media (min-width: 813px) {
    margin: 0 -7px;
  }
  ${Column} {
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 19px;
    ${Card} {
      padding: 0;
      ${CardImage} {
        @media (min-width: 360px) {
          width: 100%;
          height: 0;
        }
      }
    }
    @media (min-width: 320px) {
      max-width: 50%;
      flex: 0 0 50%;
    }
    @media (min-width: 640px) {
      max-width: 33.333%;
      flex: 0 0 33.333%;
    }
    @media (min-width: 1024px) {
      flex: 0 0 20%;
      max-width: 20%;
      margin-top: 13px;
      margin-bottom: 7px;
    }
    @media (min-width: 813px) {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  @media (min-width: 360px) {
    ${CardTitle} {
      margin-bottom: 4px;
    }
    ${CardInfo} {
      margin-bottom: 7px;
      line-height: 1.2;
    }
  }
`;

export const CardWrapType2 = styled(CardWrap)`
  padding-bottom: 19px;
  @media (min-width: 769px) {
    padding-bottom: 0;
    ${Column} {
      margin-top: 14px;
    }
  }
`;

export const CardWrapType1 = styled(CardWrap)`
  position: relative;
  padding-bottom: 81px;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 0;
  @media (min-width: 769px) {
    margin-left: -15px;
    margin-right: -15px;
  }
  ${Column} {
    margin: 20px 0 0;
    padding-left: 8px;
    padding-right: 8px;
    @media (min-width: 1024px) {
      padding-left: 15px;
      padding-right: 15px;
      flex: 0 0 25%;
      max-width: 25%;
      margin-top: 30px;
    }
  }
  ${Card} {
    padding: 0;
  }
  ${CardImage} {
    padding-top: 156px;
    @media (min-width: 1025px) {
      padding-top: 240px;
    }
  }
`;

export const CardWrapBorder = styled(CardWrapType1)`
  &:before {
    display: none;
  }
  ${Column} {
    margin-top: 30px;
  }
`;
export const BtnCheckBox = styled.button`
  outline: none;
  position: relative;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  padding: 0;
  overflow: hidden;
  background-color: ${colors.WHITE};
  border: 1px solid ${(props) => (props.checked ? `${colors.RED}` : `${colors.GREY}`)};
  ${(props) =>
    props.checked
      ? `
    overflow: visible;
    `
      : `
    overflow: hidden;
    `};
  ${(props) =>
    props.checked
      ? `
    fill: ${colors.RED}
    `
      : `
    fill: ${colors.WHITE};
    }`};
  .icon {
    width: 22px;
    height: 22px;
    margin: -1px 0 0 -1px;
  }
`;

export const BtnCheckBoxDigit = styled.button`
  outline: none;
  position: relative;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  padding: 0;
  overflow: hidden;
  color: ${colors.WHITE};
  ${(props) =>
    props.checked
      ? `
      background-color: ${colors.RED};
      border: 1px solid ${colors.RED};
    `
      : `
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.GREY};
    }`};
`;

export const CardIconWrap = styled.div`
  cursor: pointer;
  top: 18px;
  right: 18px;
  width: 16px;
  height: 16px;
  margin-top: 3px;
  &:hover {
    .icon {
      stroke: ${colors.RED};

      &-savedslide {
        fill: ${colors.RED};
      }
    }
  }
  .icon {
    stroke: ${colors.WHITE};

    &-savedslide {
      fill: ${colors.RED};
      stroke: ${colors.RED};
    }
  }
  ${Spinner} {
    width: 18px;
    height: 18px;
    border-width: 4px;
    margin-bottom: 2px;
  }
`;

export const CardPay = styled.div`
  font-family: ${fonts.robotoMedium};
  color: ${(props) =>
    (props.isPaid === "purchased" && colors.ORANGE) ||
    (props.isPaid === false && colors.RED) ||
    (props.isPaid === true && colors.GREY_DARK)};
  cursor: default;
  .icon {
    height: 16px;
    width: 16px;
    ${(props) => (props.isPaid === "purchased" ? `stroke: ${colors.ORANGE}` : null)}
  }
`;

export const CardFooter = styled.div`
  display: flex;
  margin-top: auto;
  ${CardTime} {
    margin-right: 5px;
  }
  ${CardPay} {
    display: none;
  }
  @media (min-width: 769px) {
    ${CardPay} {
      display: block;
    }
  }
`;

export const SaveText = styled.span`
  margin-left: 8px;
  color: ${colors.GREY_SEMIDARK};
`;
export const CardActionIcons = styled.div`
  display: flex;
  align-items: center;
  .icon-plus {
    fill: ${colors.WHITE};
  }

  ${CardIconWrap} + ${CardIconWrap} {
    margin-left: 15px;
  }
`;
export const CardAction = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  z-index: 1;

  ${CardTime} {
    color: ${colors.WHITE};
  }
`;
